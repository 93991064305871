























import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class CourseCardSmall extends Vue {
  private newStatus: boolean = false;
  @Prop({ default: null })
  private course!: any[];

  @Prop({ default: 0 })
  private total!: number;

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日（${day}）`;
  }
}
